/** @jsx jsx */
import React from 'react';
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Label,Input, Box, jsx } from 'theme-ui';
import styles from './profile.style';

Amplify.configure(awsconfig);

const Details = ({userInfo, user}) => {
	console.log(userInfo)
	return (
		<Box as="form" onSubmit={(e) => e.preventDefault()}>
			<Label htmlFor="firstname">Firstname</Label>
			<Input name="firstname" id="firstname" mb={3} disabled value={userInfo.firstName}/>
			<Label htmlFor="lastname">Lastname</Label>
			<Input name="lastname" id="lastname" mb={3} disabled value={userInfo.lastName}/>
			<Label htmlFor="phone">Phone</Label>
			<Input name="phone" id="phone" mb={3} disabled value={userInfo.phone_number}/>
			<Label htmlFor="email">Email</Label>
			<Input name="email" id="email" mb={3} disabled value={userInfo.email}/>						
		</Box>
	)
}


export default Details;