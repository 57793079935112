/** @jsx jsx */
import { Grid, Box, jsx } from 'theme-ui';
import PrimaryCard from '../../profile-card/primary/primary';
import styles from './primary.style';

/*const profileBlocks = [{id: 1, path: "orders", thumbnail: "../../../images/order.png", title: "your orders"},
{id: 2, path: "details", thumbnail: "../../../images/order.png", title: "your details"},
{id: 3, path: "", thumbnail: "../../../images/order.png", title: "Sign Out"}
];*/
const getBlocksData = (edges: any): any => {
	const data: any = [];
	edges.forEach((block: any, index: number) => {
		let title = '', path = '', subtitle = '';
		switch (block.node.base) {		
		  case "1-order.png":
		  	path = "orders";
		  	title = "your orders";
		  	subtitle = "view order details";
		  	break;
		  case "2-details.png":
		  	title = "your details";
		  	path = "details";
		  	subtitle = "view profile details";
		  	break;
		  case "3-signout.png":
		  	title = "sign out";
		  	path = "";
		  	subtitle = "log off";
		  	break;		  			  	
		}
		data.push({
			id: `block-${index}`,
			title: title,
			thumbnail: block.node.childImageSharp.fluid,
			path: path,
			subtitle: subtitle
		});
	});
	return data;
};

const ProfileBlocks = ({ profileBlocks }: any) => {
	const blocks = getBlocksData(profileBlocks.edges);
	return (
		<Box sx={styles.wrapper}>
			<Grid sx={styles.grid}>
				{blocks.map((item: any) => (
					<PrimaryCard
						key={`category-block--key${item.id}`}
						path={item.path}
						thumbnail={item.thumbnail}
						title={item.title}
						btnTex={item.subtitle}
					/>
				))}
			</Grid>
		</Box>
	);
};

export default ProfileBlocks;
